import ReactDOM from "react-dom";

class CsrfCookieExtractor {
  private static lastCookieStr: string = '';
  private static lastCsrfToken: string | null = null;

  private static parseCookieValue(cookieStr: string, name: string): string | null {
    name = encodeURIComponent(name);
    for (const cookie of cookieStr.split(';')) {
      const eqIndex = cookie.indexOf('=');
      const [cookieName, cookieValue]: string[] = eqIndex == -1 ? [cookie, ''] : [cookie.slice(0, eqIndex), cookie.slice(eqIndex + 1)];
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  public static getCsrfToken(cookieName: string = 'CSRF_TOKEN'): string | null {
    const cookieStr: string = document.cookie;
    if (cookieStr !== CsrfCookieExtractor.lastCookieStr) {
      CsrfCookieExtractor.lastCsrfToken = CsrfCookieExtractor.parseCookieValue(cookieStr, cookieName);
      CsrfCookieExtractor.lastCookieStr = cookieStr;
    }
    return CsrfCookieExtractor.lastCsrfToken;
  }
}

class Connect {

  public meta = {
    version: 0,
    podname: "unknown"
  };

  public current_user = {
    id: 0,
    username: "unknown",
    all_roles: "unknown",
    cookies: "unknown",
    locale: "unknown"
  };

  public user_organization = {
    id: "unknown",
    name: "unknown",
    slug: "unknown"
  };

  public tenant = {
    connect_id: "unknown",
    name: "unknown",
    tenant_id: "unknown",
    organization_id: "unknown",
    slug: "unknown",
    locale: "unknown"
  };

  public zuora_idenity = {
    user_id: "unknown",
    info: {},
    tenant_id: "unknown",
    hostname: "unknown",
    environment: "unknown"
  };


  static path() {
    if (!window.location.host.includes('connect') && !window.location.host.includes('localhost')) {
      if(window.location.pathname.includes('cnnct-no-layout')){
        return '/services/cnnct-no-layout'
      }
      return '/services/connect'
    }else{
      return ''
    }
  }

  static public_path() {
    if (!window.location.host.includes('connect') && !window.location.host.includes('localhost')) {
      if (window.location.host.includes('staging')){
        return 'https://connect-staging.apps.zuora.com'
      }
      return 'https://connect.zuora.com'
    }else{
      return ''
    }
  }

  static setImagePath(title: string) {
    let imagePath = ''
    if (title.match(/Salesforce|SF/)) {
      imagePath = Connect.public_path() + '/Salesforce-logo.jpg'
    } else if (title.match(/NetSuite|NS/)) {
      imagePath = Connect.public_path() + '/NetSuite-Logo.jpeg'
    } else {
      imagePath = Connect.public_path() + '/Zuora-logo.png'
    }
    return imagePath
  }

  async unmountReactOnRailsComponent() {
    var eles = Array.from(document.getElementsByClassName('js-react-on-rails-component'));
    if (process.env.NODE_ENV !== "production") {
      console.log("Number of components unmounting", eles.length)
    }
    eles.forEach(function(domNode) {
      if (domNode === null) {
        return;
      }
      try {
        ReactDOM.unmountComponentAtNode(domNode);
      } catch (e: any) {
        console.info(`Caught error calling unmountComponentAtNode: ${e.message} for domNode`,
          domNode, e);
      }
    });
  }

  static async proxyCall(path, method, payload, headers) {
    if (window.location.host.includes('one')) {
      headers['csrf_token'] = CsrfCookieExtractor.getCsrfToken('CSRF_TOKEN') || Connect.getCookie('CSRF_TOKEN');
      path = path.replace('/platform/gateway-proxy-v2', '/revenue/gateway-api');
    }

    const fetchOptions: RequestInit = {
      method: method,
      headers: headers,
    };
    if (method !== 'GET') {
      if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        // The payload has already been convereted at this point.
        (fetchOptions as { body: string }).body = payload;
      } else {
        // For other content types, serialize the payload to JSON
        (fetchOptions as { body: string }).body = JSON.stringify(payload);
      }
    }
    const response = await fetch(path, fetchOptions);
    return response;
  }

  static log(error: Error | string, context: any = {}) {
    if (window.location.host.includes('localhost')) {
      console.error(error);
      return;
    }
    
    const { hub, tags } = (window as any).connectSentry || {};

    if (hub) {
      hub.setTags({ ...tags, ...context });
      hub.setExtra('error', error);
      hub.captureException(error);
    } else {
      console.error(error);
    }
  }

  static getCookie(name: string) {
    const cookies = window.connect?.current_user?.cookies || [];
    const cookie = cookies.find(cookie => cookie[0] === name);
    return cookie?.[1];
  }

  static convertStringToUUID(value: string) {
    return value.trim().replace('-', '').replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
  }
}
export default Connect;

export const windmillRun = async (params: any, integrationAppId?: number) => {
  const prefix = window.location.href.includes('admin') ? '/admin' : '';
  const connect: Connect = (window as any).connect;
  const tenantId = (connect.tenant as any).connect_id;
  
  let windmillPath = '';

  if (integrationAppId == undefined) {
    // If integrationAppId is not specified, use integration_apps windmill_run
    windmillPath = `${Connect.path()}${prefix}/integration_apps/${tenantId}/windmill_run`;
  } else {
    // Otherwise, use integration_apps_settings windmill_run
    windmillPath = `${Connect.path()}${prefix}/integration_apps_settings/${integrationAppId}/${tenantId}/windmill_run`;
  }

  const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
  const options = {
    method: 'POST',
    headers: new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf
    }),
    body: JSON.stringify(params)
  };
  const response = await fetch(windmillPath, options);
  const data = await response.json();
  return JSON.parse(data?.['body']?.['response']);
};